@import '../../../Assets/Styles/variables';
@import '../../../Assets/Styles/mixins';

.shop {
    &-container {
        margin: 0 10%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: start;
        padding-top: 87px;
        gap: 150px;
        flex-wrap: wrap;
        align-items: center;

    }

    &_info {
        &_head {
            border-bottom: 1px solid #a7a7a7;

            h4 {
                color: $black;
                font-size: $md-font;
                font-weight: bold;
            }

            p {
                font-family: "Pretendard-Light";
                font-size: $md-font;
                font-weight: 400;
            }
        }

        &_detail {
            margin-top: 30px;
            &_item {
                display: flex;
                justify-content: start;
                align-items: start;
                flex-direction: row;
                &>* {
                    font-size: $text-font;
                }

                p {
                    font-weight: 700;
                    width: 25%;
                }

                span {
                    font-family: "Pretendard-Light";
                    font-weight: 400;
                    width: 75%;
                }
            }
            .button{
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: center;
            }
            &_seller {
                margin-top: 40px;
                .amazon{
                    display: flex;
                    width: 100%;
                    height: 100%;
                    margin-bottom: -10px;
                    margin-top: 10px;
                    align-items: center;
                    justify-content: center;
                }
                .iherb{
                    display: flex;
                    width: 100%;
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                }
                .box-pink {
                    display: flex;
                
                    width:350px;
                    height: 100px;
                    margin-bottom: 20px;
                    border-radius: 45px;
                    text-align: center;
                    justify-content: center;    
                    align-items: center;
                    img{
                        
                    }
                }
               
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .shop-container{
            padding-top: 10px;
            gap: 20px;
            .item_01{
                display: flex;
                justify-content: center;
                img{
                    width: 80%;
                }
            }
            .shop_info_detail_seller{
                margin-top: 10px;
            }
        }
    }
}