.intro-main {
    background-color: #fff2f9;

    &-container {
        margin: 0 10%;
        padding: 50px 0 130px;

        .title {
            padding-bottom: 50px;

            span {
                font-size: 40px;
                line-height: 34px;
                color: #000000;
                font-weight: bold;
            }
        }

        .intro-main-img {
            padding: 60px 0 100px;
            display: flex;
            justify-content: center;
        }

        .intro-main-target {
            padding-bottom: 60px;
        }
        @media screen and (max-width: 1200px) {
            margin: 0 5%;
            padding: 30px 0 80px;
        }
        @media screen and (max-width: 767px) {
            .title {
                padding-bottom: 20px;
                span {
                    font-size: 25px;
                }
            }
            .intro-main-img {
                padding: 30px 0 40px;
            }
    
            .intro-main-target {
                padding-bottom: 30px;
            }
        }
    }

}