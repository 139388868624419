@import "../../../../../Assets/Styles/variables";
@import "../../../../../Assets/Styles/mixins";

.section-fourth_v2 {
    height: 100%;
    background-color: #ffebf6;

    &-container {
        margin: 0 10%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 50px;
    }

    &-list {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 50px;
        flex-wrap: wrap;
    }

    &-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        margin-inline: auto;

        img {
            border-radius: 100px;
            width: 200px;
            height: 200px;
        }

        h4 {
            font-size: 22px;
            font-family: "PretendardVariable";
            color: $text-pink;
            max-width: 190px;
            min-height: 100px;
            margin-top: 25px;


        }

        .en {
            font-size: 22px;
        }

        .kr {

            min-height: 50px;
            margin-top: 0px;
            margin-bottom: 0px;
            font-size: 18px;
        }

        p {
            font-size: $text-font;
            color: $black;
            max-width: 200px;
            min-height: 230px;
        }


    }


    @media screen and (max-width: 1279px) {
        &-list {
            display: block;
            width: 100%;
            gap: 30px;
        }

        &-item {
            display: inline-flex;
            align-items: center;
            margin-right: auto;
            width: calc(100% / 4);
            margin-bottom: 20px;
        }
    }

    @media screen and (max-width: 1099px) {


        &-item {

            width: 33%;

            p {
                min-height: 0;
            }
        }


    }

    @media screen and (max-width: 767px) {
        &-item {
            width: 100%;

            h4 {
                text-align: center;
            }

            h4,
            p {
                max-width: 100%;
                min-height: 0;
            }
        }
    }


}