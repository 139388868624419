@import "../../../../../Assets/Styles/variables";
@import "../../../../../Assets/Styles/mixins";

.section-two_v2 {
  height: 100%;
  .border-section{
    padding-bottom:0px;
    padding-top:0px;
  }
  &-container {
    margin: 0 10%;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: column;

    // margin-top :100px;
    .title{
      margin-top: 100px;
      img {
        margin: auto;
        width: 80%;
      }
    }
    .detail{
      img {
        margin: 10% 0 0 10%;
        width: 95%;
      }
    }


    @media screen and (max-width: 767px) {
      // margin: 0 5%;
      flex-direction: column;
      justify-content: center;

      .title {
        padding-left: 0px;
        // padding-top: 30px;

      }

      .detail {
        padding-bottom: 0;
      }
    }
  }
}