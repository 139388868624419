footer {
    --fsLogo: 30px;
    --fsDetail: 18px;
    background-color: #333333;

    a {
        color: #838383;
    }

    color: #838383;

    .footer-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1570px;
        padding: 50px 25px;
        margin: 0 auto;

        .leftItem {
            .logo {
                color: #fff;
                font-size: var(--fsLogo);
            }

            .detail {
                padding: 35px 0;
                line-height: 1.5;
                font-size: var(--fsDetail);
            }

            .list {
                display: flex;

                li {
                    font-size: var(--fsDetail);
                    padding-right: 20px;
                }
            }
        }

        .rightItem {
            a {
                color: #fff;
                font-size: var(--fsDetail);
            }

            li {
                padding-top: 15px;
            }
        }
    }

    @media screen and (max-width: 880px) {
        --fsLogo: 23px;
        --fsDetail: 16px;
    }

    @media screen and (max-width: 767px) {
        --fsLogo: 23px;
        --fsDetail: 14px;

        .footer-container {
            flex-direction: column;
            align-items: flex-start;
            padding: 30px 20px;

            .leftItem {
                .detail {
                    br {
                        display: none;
                    }
                }

                .list {
                    li {
                        padding-right: 20px;
                    }
                }
            }

            .rightItem {
                padding-top: 20px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                justify-content: space-between;
                width: 100%;
            }
        }
    }
}