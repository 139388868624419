@import '../../../Assets/Styles/variables';
@import '../../../Assets/Styles/mixins';

.faq {
    display: flex;
    font-family: "PretendardVariable";
    background-color: #ffebf6;

    .faq-container {
        display: flex;
        flex-direction: column;
        margin: 60px 10% 0;
        width: 100%;

        .document-list {
            display: flex;
            flex-direction: column;
            width: 100%;

            .document {
                display: flex;
                flex-direction: column;
                padding: 20px 40px;
                background-color: #d3e5e8;
                transition: background-color 0.3s ease-out;
                margin-bottom: 20px;
                border-radius: 30px;
                width: 100%;
                max-width: 1280px;

                .title {
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 23px;

                    img {
                        width: 12px;
                        height: 8px;
                        transform: rotate(180deg);
                        transition: all 0.1s linear;
                    }
                }

                .answer {
                    overflow: hidden;
                    transition: max-height 0.3s ease-out; // 애니메이션 속성 설정해주기
                    font-family: "Pretendard-Light";
                    font-size: 15px;
                    max-height: 0;

                    p {
                        margin: 20px 0px;
                        white-space: pre-line;
                    }

                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }

            .open {
                background-color: #fff;
                transition: background-color 0.3s ease-out;

                .title {
                    img {
                        transform: rotate(0deg);
                        // transition: all 0.1s linear;
                    }
                }
            }
        }
    }

    .bottom {
        margin-bottom: 116px
    }

    @keyframes fade-out {
        from {
            margin: 20px 0px;
            opacity: 1;
        }

        to {
            margin: 0px 0px;
            opacity: 0;
        }
    }

    @keyframes fade-in {
        from {
            margin: 0px 0px;
            opacity: 0;
        }

        to {
            margin: 20px 0px;
            opacity: 1;
        }
    }
}