#superCondria {
    .section {
        // height: 100vh;
        @media screen and (max-width: 1319px) {
            height: auto;
        }
        &-line {
            margin: auto;
        }
    }
    .section {
        &-line {
            height: 2px;
        }
    }
    @media screen and (max-width: 1023px) {
        .section {
            &-line {
                height: 1px;
            }
        }
    }
}