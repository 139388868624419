@import "../../../../../Assets/Styles/variables";
@import "../../../../../Assets/Styles/mixins";

.section-three_v2 {
    height: 100%;

    &-container {
        margin: 0 10%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h3 {
            font-size: $md-font;
            font-family: "Pretendard-SemiBold";
        }
    }


}