.titleMain {
    height:50px;
    display: flex;
    align-items: center;
    span {
        font-size: 25px;
        color: #ffffff;
        font-weight: bold;
        // padding: 12px 0;
        margin-left: 15px;
        @media screen and (max-width: 1550px) {
            font-size: 20px;
        }
        @media screen and (max-width: 1200px) {
            font-size: 20px;
        }
        @media screen and (max-width: 767px) {
            font-size: 16px;
        }
        @media screen and (max-width: 510px) {
            font-size: 10px;
        }
    }
}

.titleMain-detail {
    padding: 15px;
    white-space: pre;
    span {
        font-size: 16px;
        line-height: 1.4;
        color: #000000;
        font-weight: 500;
    }
    @media screen and (max-width: 1625px) {
        white-space:unset;
    }
    @media screen and (max-width: 1200px) {
        padding: 10px;
        white-space:unset;
    }

    @media screen and (max-width: 767px) {
        white-space:unset;
        span {
            font-size: 14px;
        }
    }
    @media screen and (max-width: 510px) {
        span {
            font-size: 10px;
        }
    }
}

.danger {
    background-color: #e04c8a;
}

.primary {
    background-color: #91a9ea;
}