@import '../../../Assets/Styles/variables';
@import '../../../Assets/Styles/mixins';

.subtitle-page {
    &_container {
        border-top: 1px solid #a7a7a7;
        margin: 0 10% 0;
    }
    p {
        padding: 20px 0 20px 10px;
        font-size: 20px;
        color: #000000;
        font-weight: bold;
        margin-bottom: 0;
    }

    
    @media screen and (max-width: 1200px) {
        &_container {
            margin: 5px 5% 0;
        }
    }
    @media screen and (max-width: 767px) {
        p{
            padding: 15px 0 20px 10px;
        }
    }
    
}

.box-pink {
    background-color: #fff2f9;
}