.map {
    background-color: #e4ebfe;

    &-container {
        margin: 0 10%;
        padding: 50px 0 130px;

        .title {
            padding-bottom: 50px;

            span {
                font-size: 40px;
                line-height: 34px;
                color: #000000;
                font-weight: bold;
            }
        }

        .map-study-weight {
            padding: 80px 0 60px;
        }
        .map-study-improve {
            padding: 40px 0 100px;
        }

        .map-study-img {
            display: flex;
            justify-content: center;
            padding: 60px 0 100px;
        }

        @media screen and (max-width: 1200px) {
            margin: 0 5%;
            padding: 30px 0 80px;
        }

        @media screen and (max-width: 767px) {
            .title {
                padding-bottom: 20px;

                span {
                    font-size: 25px;
                }
            }

            .map-study-weight {
                padding: 30px 0 30px;
            }

            .map-study-img {
                padding: 30px 0 40px;
            }

            .map-study-protects {
                padding: 20px 0 0px;
            }

        }
    }
    @media screen and (max-width: 767px) {
        .map-study-improve{
        padding: 0 0 0;
        }
    }
}