.heroSection {
    --fsTit: 50px;
    --fsDetial: 36px;
    --fsButton: 22px;
    --widthBtn: 170px;
    --heightBtn: 55px;
    position: relative;

    &-img {
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -30%);
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;

        .title {
            font-size: var(--fsTit);
            color: #FFE365;

        }

        .detail {
            font-size: var(--fsDetial);
            color: #fff;
            padding: 0px 0 35px;
        }

        .button {
            width: var(--widthBtn);
            height: var(--heightBtn);
            border-radius: 28px;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0px solid #11080e;
            cursor: pointer;

            span {
                font-size: var(--fsButton);
                color: #000000;
            }
        }
    }

    @media screen and (max-width: 1080px) {
        --fsTit: 30px;
        --fsDetial: 22px;
        --fsButton: 16px;
        --widthBtn: 120px;
        --heightBtn: 40px;
    }

    @media screen and (max-width: 767px) {
        --fsTit: 22px;
        --fsDetial: 20px;
        --fsButton: 16px;
        --widthBtn: 120px;
        --heightBtn: 40px;

        &-img {
            height: 500px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}