html {
  box-sizing: border-box;
}
html {
	font-size: 50.625%; // 8.4px
	@media (min-width: 769px) {
		font-size: 56.25%; // 9.2px
	}
	@media (min-width: 1281px) {
		font-size: 62.5%; // 10px
	}
	// @media (min-width: 1921px) {
	// 	font-size: 77%; // 12.32px
	// }
}
html,
body {
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  // scroll-behavior: smooth;

  line-height: 1.4 !important;
  font-family: $pretendardVariable;
  // overflow-x: hidden;
}

body {
  font-size: 18px;
  background-color: white;
  max-width: 100%;
 
}
* {
  margin: 0;
  padding: 0;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
input,
textarea,
select,
button {
  outline: none;
}
input {
  line-height: normal;
}
label,
button {
  cursor: pointer;
}
a {
  text-decoration: none;
}
img {
  display: block;
  max-width: 100%;
}
