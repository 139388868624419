@import "../../../../../Assets/Styles/variables";
@import "../../../../../Assets/Styles/mixins";

.section-six_v2 {
    height: 100%;

    &-container {
        margin: 0 10%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 80px;
    }

    h3 {
        font-weight: bold;
    }

    &-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 60px;

    }

    &-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 40px;

        img {
            border-radius: 30px;
            width: 200px;
            height: 200px;
            object-fit: cover;
            background-position: center center;
        }

        h4 {
            font-size: 23px;
            font-family: "PretendardVariable";
            color: $text-pink;
        }

        p {
            font-size: $text-font;
            color: $black;
            max-width: 200px;
            min-height: 240px;
            font-family: "Pretendard-Light";
            // font-weight: bold;
            letter-spacing: 0.1px;
        }
    }

    @media screen and (max-width: 1319px) {
        &-content {
            display: block;
            width: 100%;
            gap: 30px;
        }

        &-item {
            display: inline-flex;
            align-items: center;
            margin-right: auto;
            width: calc((100% / 4) - 4px);
            margin-bottom: 20px;
            margin-right: 2px;
            gap: 10px;
        }
    }

    @media screen and (max-width: 1099px) {

        &-item {
            width: 33%;
        }
    
    }

    @media screen and (max-width: 767px) {
        &-item {
            align-items: center;
            gap: 10px;
            width: 100%;
            p {
                min-height: 0;
                max-width: 100%;
                text-align: left;
            }
        }
    }

}