@import "../../../../../Assets/Styles/variables";
@import "../../../../../Assets/Styles/mixins";

.section-five_v2 {
    height: 100%;

    &-container {
        margin: 0 10%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    p {
        font-size: clamp(14px, 2vw, $sm-font);
        color: $black;
        font-family: "Pretendard-Light";
        font-weight: bold;
    }

    &-content {
        text-align: center;

        h3 {
            color: $text-pink;
            max-width: 1000px;
            font-size: clamp($md-font, 2vw, $lg-font);
        }
    }

    &-box {
        margin-top: -90px;
        max-width: 1280px;
        &-white {
            background-color: $white;
            padding: 60px 80px;
            border-radius: 40px;
            p{
                font-family: "Pretendard-Light";
                font-weight: unset;
            }
        }

        img {
            margin-bottom: -10px;
        }
    }

    @media screen and (max-width: 1299px) {
        &-box {
            margin-top: 0;

            &-white {
                padding: 50px;

            }
        }
    }

    @media screen and (max-width: 767px) {
        &-box {
            &-white {
                padding: 30px;

            }
        }
    }



}