// Montserrat
@import "./variables.scss";
@import "./mixins";
@import "./fonts";

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  -webkit-tab-highlight-color: transparent;
  user-select: none;
  // @include family--montserrat;
}

body {
  --widthCircle: 11px;
  font-family: Pretendard_Variable;
  ::-webkit-scrollbar {
    display: none;
  }
}
::-webkit-scrollbar {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

video {
  max-width: 100%;
}

img {
  max-width: 100%;
}


.p {
  color: $color-text;
  line-height: $lh-p;
  font-size: $text-font;
  font-weight: normal;
}

.text-head {
  font-family: "Pretendard-SemiBold";
  font-size: clamp(14.5px, 3vw, 18.5px);
  font-weight: 600;
  line-height: 1.62;
  text-align: left;
  color: $bg-lang-active;
}

.container {
  width: 100%;
  margin: 0 auto;

  @include tablet {
    width: 90%;
  }
}

.container-fluid {
  width: 1860px;
  margin: 0 auto;

  @include laptop {
    width: 1460px;
  }
}


.h3 {
  color: $black;
  font-size: clamp(30px, 2vw, $lg-font);
  font-family: "PretendardVariable";

}

.none-border {
  border: transparent;
}

.page-home {
  background-color: #ffebf6 !important;
  padding-inline: 3%;
}

.border-section {
  padding-bottom: 100px;
  padding-top: 100px;
  @media screen and (max-width: 767px) {
    padding-block: 80px;
    padding-bottom: 140px;
  }
}
