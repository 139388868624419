.reference {
    margin: 0 10%;
    padding: 60px 0 70px;

    &-title {
        font-size: 30px;
        line-height: 34px;
        color: #000000;
        font-weight: bold;
        padding-bottom: 40px;
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
            padding-right: 12px;
        }
    }

    p {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        font-weight: 500;
    }
    @media screen and (max-width: 1200px) {
        margin: 0 5%;
        padding: 40px 0 40px;
    }
    @media screen and (max-width: 767px) {
        padding: 30px 0 60px;
        &-title {
            font-size: 25px;
        }
        p{
            font-size: 20px;
        }
    }
.open{
    transform: rotate(180deg); // 화살표 아이콘 방향돌리기 
}
    &-detail{
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.3s ease-out; // 애니메이션 속성 설정해주기
        p{
            font-size: 16px;
        }
    }
    @media screen and (max-width: 1550px) {
        p{
        font-size: 16px;
        }
    }
    @media screen and (max-width: 1200px) {
        p{
            font-size: 16px;
            }
    }
    @media screen and (max-width: 767px) {
        p{
            font-size: 16px;
            }
    }
    @media screen and (max-width: 510px) {
        p{
            font-size: 10px;
            }
    }
}