@import "../../../Assets/Styles/index.scss";

.header {
  top: 0px;
  right: 0;
  left: 0;
  height: 82px;
  z-index: 5;
  color: #fff;

  a {
    color: #fff;
  }
  .menu_items {
    a {
      &:hover {
        color: #e04c8a;
      }
    }
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1570px;
    padding: 0px 25px;
    margin: 0 auto;
    height: 82px;
  }

  &_menu {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    .header_title {
      font-size: $head-font;
      font-weight: bold;
      cursor: pointer;

      img {
        width: 124px;
        height: auto;
      }
    }

    .list_menu {
      display: flex;
      margin-bottom: 0;
      flex: 1;
      align-items: center;
      justify-content: center;

      .menu_items {
        padding-left: 46px;

        a {
          font-family: "Pretendard_Variable";
          font-size: 15px;
        }

        .activeItemsPage {
          color: #e04c8a
        }

      }
    }
  }

  .header_lang {
    display: flex;
    gap: 12px;
    align-items: center;

    &_button {
      width: 120px;
      height: 35px;
      border-radius: 28px;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #fff;
      cursor: pointer;

      span {
        font-size: 16px;
      }
    }

    &_choooseLang {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      span {
        padding-right: 5px;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }

    .lang {
      opacity: 0.6;
      cursor: pointer;
      // padding-left: 9px;

    }

    .active {
      opacity: 1;
    }
  }

  .header_mobile_icon {
    display: none;
  }

  @media screen and (max-width: 1200px) {
 

    &_menu {
      .list_menu {
        .menu_items {
          padding-left: 20px;

          a {
            font-size: 13px;
          }
        }
      }
    }


  }

  @media screen and (max-width: 960px) {

    &_menu {
      justify-content: flex-start;
      .list_menu {
        display: none;
      }

    }

    .header_lang {
      display: none;
    }

    .header_mobile_icon {
      display: block;
    }

  }
}

.activeNav {
  background-color: #fff;
  color: #000;

  a {
    color: #000;
  }
}

.menu-mobile-lang {
  .menu-icon {
    display: block;
    width: 25px;
    height: 20px;
    cursor: pointer;
    position: relative;
    z-index: 99999;

    span {
      display: block;
      height: 2px;
      width: 100%;
      border-radius: 30px;
      background-color: #211815;
      position: absolute;
      transition: background-color 0.3s ease-out;
      transition: all 0.35s;
    }

    span:nth-child(1) {
      top: 0;
    }

    span:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
    }

    span:nth-child(3) {
      bottom: 0;
    }
  }

  .check {
    span {
      background-color: #211815;
      transition: background-color 0.3s ease-out;
      transition: all 0.3s;
    }

    span:nth-child(1) {
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }

    span:nth-child(2) {
      display: none
    }

    span:nth-child(3) {
      top: 50%;
      transform: translateY(-50%) rotate(-45deg);
    }
  }

  .header-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: fixed;
    background-color: #fff;
    top: 0;
    right: 0;
    width: 85%;
    height: 100vh;
    z-index: 99;
    transform: translateX(100%);
    transition: 500ms;

    .logo {
      margin-top: 25%;
      margin-bottom: 40px;
      // width: 124px;
      height: auto;
      span {
        color: #fff;
        font-size: 26px;
      }
    }

    .icon-close {
      display: flex;
      padding: 20px 20px;
      justify-content: flex-end;

      span {
        color: #000;
        font-size: 30px;
      }
    }

    .menu-mobile-items {
      font-family: "Pretendard_Variable";
      font-weight: bold;
      margin-bottom: 40px;

      .list_menu {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        gap: 35px;


        .menu_items {

          text-align: center;

          a {
            color: #fff;

            font-size: 20px;
          }

        }

      }
    }
  }

  .menu-mobile-lang {
    display: flex;
    flex-direction: column;
    align-items: center;
    a,span {
      color: #fff;
    }

    .header_lang {
      display: flex;
      justify-content: center;
      gap: 30px;
      &_button {
        border-color: #fff;
      }
      .lang {
        opacity: 0.6;
        cursor: pointer;
      }

      .active {
        opacity: 1;
      }
      &_choooseLang {
        color: #fff;
      }
    }
  }

  .openMenu {
    transform: translateX(0);
    background-color: #333333;
  }
}

@media (min-width: 960px) {
  .menu-mobile-lang {
    display: none;
  }
}