@import '../../../../../Assets/Styles/variables';
@import '../../../../../Assets/Styles/mixins';

.section-one_v2 {
  @include family--pretendard;
  height: 800px;
  .border-section {
    padding-top: 0;
    padding-bottom: 0;
  }

  &-container {
    display: flex;
    align-content: center;
    margin: 0 10%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: start;
    flex-wrap: wrap;
    // gap: 20px;
    .img {
      text-align: center;
      img {
        width: 95%;
        margin: auto;
      }
    }
  }

  .detail {
    margin-top: 55px;
    .img-small {
      margin-bottom: 20px;
      margin-left: auto;
    }

    h2 {
      font-size: clamp(20px, 2vw, $md-font);
      color: $text-pink;
      font-family: "Pretendard-SemiBold";
    }

    p,
    li {
      font-size: clamp(16px, 2vw, $sm-font);
    }

    p {
      color: $black;
      font-family: "Pretendard-Light";
      max-width: 600px;
    }

    ul {
      list-style: inside;

      li {
        font-family: "Pretendard-SemiBold";
        line-height: 35px;
      }

      margin-bottom: 80px;
    }

    &_images {
      display: flex;
      gap: 10px;
      margin-top: 20px;

      img {
        object-fit: contain;
      }
    }
  }

  @media screen and (max-width: 1299px) {
    padding-top: 100px;
    padding-bottom: 100px;
    height: 100%;
    .detail {
      margin-top: 5px;
    }
  }

}