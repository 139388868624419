.sharedComponent {
    background-color: #EDF3BE;

    &-container {
        height: 722px;
        max-width: 1500px;
        padding: 0px 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;

        .leftItem {
            .title {
                font-size: 50px;
                color: #000000;
                font-weight: bold;
                white-space: pre-line;
            }

            .detail {
                display: flex;
                padding-top: 50px;
                align-items: center;

                .button {
                    width: 150px;
                    height: 55px;
                    border-radius: 28px;
                    background-color: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 0px solid #11080e;
                    cursor: pointer;
                    margin-left: 20px;

                    span {
                        padding-right: 10px;
                        font-size: 25px;
                        color: #000000;
                    }

                    img {
                        width: 20px;
                        height: 25px;
                        object-fit: contain;
                        padding-top: 3px;
                    }
                }
            }
        }

        .rightItem {
            // width: 50%;
        }

        @media screen and (max-width: 767px) {
            flex-direction: column !important;
            height: 100%;
            align-items: flex-start;

            .leftItem {
                padding-bottom: 50px;
                padding-top: 50px;

                .title {
                    font-size: 30px;
                    white-space: normal;
                }

                .detail {
                    padding-top: 20px;
                    flex-direction: column;
                    align-items: flex-start;

                    .button {
                        width: 120px;
                        height: 45px;
                        margin-top: 20px;
                        margin-left: 0;

                        span {
                            font-size: 18px;
                        }
                    }
                }
            }

            .rightItem {
                padding: 25px;
                padding-bottom: 0;
            }
        }
    }
}

.content_0 {
    .sharedComponent-container {
        .rightItem {
            img {
                width: 600px;
            }
        }

        .detail {
            img {
                width: 120px;
            }
        }
    }
}


.content_1 {
    .sharedComponent-container {
        .rightItem {
            img {
                width: 500px;
            }
        }

        .detail {
            img {
                width: 120px;
            }
        }
    }
}

.content_2 {
    .sharedComponent-container {
        .rightItem {
            img {
                width: 720px;
            }
        }

        .detail {
            img {
                width: 120px;
            }
        }
    }
}

.content_3 {
    .sharedComponent-container {
        .rightItem {
            img {
                width: 640px;
            }
        }

        .detail {
            img {
                width: 120px;
            }
        }
    }
}

.content_4 {
    .sharedComponent-container {
        .rightItem {
            img {
                width: 620px;
            }
        }

        .detail {
            img {
                width: 120px;
            }
        }
    }
}

.content_5 {
    .sharedComponent-container {
        .rightItem {
            img {
                width: 300px;
            }
        }

        .detail {
            img {
                width: 120px;
            }
        }
    }
}